<template>
  <div class="home">
    <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
      <ol class="carousel-indicators">
        <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
        <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
      </ol>
      <div class="carousel-inner">
        <div class="carousel-item active">
          <img :src="imageUrl+'/home-1.jpg'" class="d-block w-100" alt="..." />
        </div>
        <div class="carousel-item">
          <img :src="imageUrl+'/home-2.jpg'" class="d-block w-100" alt="..." />
        </div>
      </div>
    </div>
    <div style="margin-top:20px"></div>
    <div class="swin-sc-title text-center">
      <h3 class="main-title title">品牌介绍</h3>
      <p class="sub-title">
        <span>Brand Introduction</span>
      </p>
      <div class="title-shape">
        <span class="shape"></span>
      </div>
    </div>
    <div class="row">
      <div class="col-1"></div>
      <div class="col-10">
        <div class="text-description">
          <p style="text-align: left;">
            <span style="font-size: 14px; color:#999999;">隐形保镖是高性能汽车膜品牌，致力于为全球车主提供高品质汽车漆面保护膜和隔热膜产品，以</span>
            <span style="font-size: 14px; color:#ecbd4d;">“让每位车主都能享受科技带来的驾乘乐趣”</span>
            <span
              style="font-size: 14px; color:#999999;"
            >为愿景，甄选进口优质脂肪族TPU基材，立足国内车主的用车环境和使用需求，专注高性能汽车膜的生产。</span>
          </p>
          <p style="text-align: left;">
            <span
              style="font-size: 14px; color:#999999;"
            >隐形保镖专注于抗老化、抗黄变车衣的研发和生产，整合行业头部企业资源，使用美国Lubrizol高性能脂肪族TPU作为原膜，生产出具有长效抗黄变、超厚耐磨的第三代漆面保护膜！</span>
          </p>
          <div class="text-center">
            <button class="button" @click="switchMenu('about')">了解详情</button>
          </div>
        </div>
      </div>
      <div class="col-1"></div>
    </div>
    <video class="d-block w-100" controls="controls">
      <source :src="imageUrl+'/home-1.mp4'" type="video/mp4" />Your browser does not support the video tag.
    </video>

    <div class="swin-sc-title text-center" style="margin-top:20px;">
      <h3 class="main-title title">产品简介</h3>
      <p class="sub-title">
        <span>Product Introduction</span>
      </p>
      <div class="title-shape">
        <span class="shape"></span>
      </div>
    </div>
    <img :src="imageUrl+'/home-3.jpg'" class="img-fluid w-100" alt="TOP系列" @click="switchMenu('productTop')"/>
    <img :src="imageUrl+'/home-4.jpg'" class="img-fluid w-100" alt="PRO系列" @click="switchMenu('productPro')"/>
    <img :src="imageUrl+'/home-5.jpg'" class="img-fluid w-100" alt="MAX系列" @click="switchMenu('productMax')"/>
    <img :src="imageUrl+'/home-6.jpg'" class="img-fluid w-100" alt="LUX系列" @click="switchMenu('productLux')"/>
    <button
      style="margin: 10px 0px 10px 0px"
      class="button"
      @click="switchMenu('productTop')"
    >了解更多产品</button>

    <div class="swin-sc-title text-center" style="margin-top:20px;">
      <h3 class="main-title title">四大优势</h3>
      <p class="sub-title">
        <span>Four Advantage</span>
      </p>
      <div class="title-shape">
        <span class="shape"></span>
      </div>
    </div>
    <div class="row">
      <div class="col-1"></div>
      <div class="col-10">
        <div class="text-description" style="margin-top:40px;">
          <p style="text-align: center;">
            <span style="font-size: 14px; color:#999999;">
              从脂肪族TPU基材保障，到整套先进的生产工艺，再到完善的售后体系，隐形保镖提供的不仅是高品质的漆面保护，
              更是无忧的驾乘乐趣。我们的渴望是为客户创造更多的价值
            </span>
          </p>
        </div>
        <h4>优质基材，源头品质</h4>
        <p>隐形保镖车衣基材均为进口优质脂肪族TPU，与普通TPU相比，具有更优异的产品耐黄性和抗刮蹭能力.</p>
      </div>
      <div class="col-1"></div>
    </div>
    <img :src="imageUrl+'/home-8.jpg'" class="d-block w-100" alt="..." />

    <div class="row">
      <div class="col-1"></div>
      <div class="col-10">
        <h4 style="margin-top: 20px">先进工艺，工厂保障</h4>
        <p>隐形保镖拥有先进的生产设备，掌握整套汽车膜生产工艺，从生产环节严把质量关，每一卷隐形保镖产品都有工厂保障！</p>
      </div>
      <div class="col-1"></div>
    </div>
    <img :src="imageUrl+'/home-9.jpg'" class="d-block w-100" alt="..." />

    <div class="row">
      <div class="col-1"></div>
      <div class="col-10">
        <h4 style="margin-top: 20px">实时数据支撑</h4>
        <p>数据研发团队实时更新主流车型数据，根据不同部位设计更精准、易施工的包边预留，确保每一寸膜都与爱车漆面紧密贴合。</p>
      </div>
      <div class="col-1"></div>
    </div>
    <img :src="imageUrl+'/home-10.jpg'" class="d-block w-100" alt="..." />

    <div class="row">
      <div class="col-1"></div>
      <div class="col-10">
        <h4 style="margin-top: 20px">高端品质，亲民价格</h4>
        <p>隐形保镖以“让每位车主都能享受科技带来的驾乘乐趣”为愿景，从原料到生产严控产品质量，工厂直接发货，砍掉中间环节，同样的高端品质，价格更亲民。</p>
      </div>
      <div class="col-1"></div>
    </div>
    <img :src="imageUrl+'/home-11.jpg'" class="d-block w-100" alt="..." />

    <div class="swin-sc-title text-center" style="margin-top: 25px">
      <h3 class="main-title title">质保范围</h3>
      <p class="sub-title">
        <span>Scope Warranty</span>
      </p>
      <div class="title-shape">
        <span class="shape"></span>
      </div>
    </div>

    <div class="row">
      <div class="col-1"></div>
      <div class="col-10">
        <div style="color:#999999; font-size:15px;">
          <p>为了保障您的消费权益，享受隐形保镖厂家的质保，您的漆面保护膜必须从隐形保镖官方渠道购买，并经隐形保镖合作门店施工，且在正常环境下使用。自装贴日起的质保年限内，厂商对仅因制造或者材料缺陷造成的开裂、起泡提供质保服务。</p>
          <p>此外，此质量保证仅在产品由隐形保镖授权贴膜技师按照生产厂商提供的标准施工流程安装于汽车原厂漆表面，并使用推荐的保养方法维护的情况下适用。</p>
          <p>本质保范围不包括由于产品使用、保养、清洗不当或误操作、施工于非原厂漆面、自然灾害、意外事故、正常磨损等其它与产品生产或材料问题无关原因而产生的损失。</p>
        </div>
      </div>
      <div class="col-1"></div>
    </div>
  </div>
</template>

<script>
import data from '../../config/index'
export default {
  name: 'Home',
  components: {},
  data() {
    return {
      imageUrl: data.imageUrl
    }
  },
  methods: {
    switchMenu(path) {
      this.$router.push({ path: path })
    }
  }
}
</script>

<style scoped>
@import '../../assets/css/home.css';
</style>